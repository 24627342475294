// map_get($theme-colors, background_colorbackground_color): #16171c;
// map_get($theme-colors, primary_color): #16171c;
// $dark_primary_color: #22232a;
// $dark_secondary_color: #22232a;
// map_get($theme-colors, secondary_color): #36393f;
// map_get($theme-colors, sub_secondary_color): #2f3034;
// map_get($theme-colors, highlight_color): rgb(0, 136, 255);
// map_get($theme-colors, dark_highlight_color): rgb(16, 53, 86);
// map_get($theme-colors, text_color): white;
// map_get($theme-colors, secondary_text_color): rgb(119, 119, 119);
// $color_border: map_get($theme-colors, secondary_color);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

$light-theme-colors: (
  'background_color': #fbfbfa,
  'primary_color': #ffffff,
  'dark_primary_color': #f6f8fa,
  'dark_secondary_color': #e0e5eb,
  'secondary_color': #f6f8fa,
  'sub_secondary_color': #0a96ff,
  'highlight_color': #0a96ff,
  'light_highlight_color': #e1edfd,
  'dark_highlight_color': #00477d,
  'text_color': rgb(0, 0, 0),
  'dark_text_color': rgb(111, 111, 111),
  'text_color_contrast': white,
  'dark_text_color_contrast': rgb(234, 234, 234),
  'secondary_text_color': rgb(42, 42, 42),
  'color_border': #dfdfdf,
  'error_color': #e5534b,
  'error_text_color': #e5534b,
  'success_color': #2bb049,
  'success_text_color': #2bb049,
  'logo': #00c2ff,
);

$dark-theme-colors: (
  'background_color': #16171c,
  'primary_color': #16171c,
  'dark_primary_color': #22232a,
  'dark_secondary_color': #22232a,
  'secondary_color': #36393f,
  'sub_secondary_color': #2f3034,
  'highlight_color': #0a96ff,
  'dark_highlight_color': #00477d,
  'text_color': white,
  'text_color_contrast': rgb(29, 29, 29),
  'dark_text_color_contrast': black,
  'secondary_text_color': rgb(119, 119, 119),
  'color_border': #36393f,
  'error_color': red,
  'error_text_color': red,
);

$theme-colors: $light-theme-colors;

:export {
  @each $key, $value in $theme-colors {
    #{unquote($key)}: $value;
  }
}

html {
  overflow: hidden;
}

* {
  // font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  //   'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  //   'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Albert Sans', sans-serif;
  box-sizing: border-box;
  position: static;
  user-select: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: map_get($theme-colors, background_color);
  color: map_get($theme-colors, text_color);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: map_get($theme_colors, secondary_color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map_get($theme_colors, dark_secondary_color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 600px) {
  .login-img {
    display: none;
  }
}

.ant-layout-header {
  background-color: map_get($theme-colors, primary_color);
  color: map_get($theme-colors, text_color);
}

.ant-layout-content {
  background-color: transparent;
  color: map_get($theme-colors, text_color);
}

.ant-layout-sider .ant-layout-sider-children {
  background-color: map_get($theme-colors, primary_color) !important;
  border-right: 1px solid map_get($theme-colors, color_border);
}

.ant-menu {
  background-color: map_get($theme-colors, primary_color) !important;
  padding: 0px 5px;
  margin-top: 10px;
}

.ant-menu-submenu-title {
  color: map_get($theme-colors, text_color) !important;
}

.ant-menu-item {
  color: map_get($theme-colors, dark_text_color) !important;
  font-size: 15px;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  &.ant-menu-item-selected {
    color: map_get($theme-colors, dark_highlight_color) !important;
    background-color: map_get($theme-colors, light_highlight_color) !important;
    border-radius: 10px !important;
    font-weight: bold;
  }
}

@media only screen and (max-width: 992px) {
  .desktop-navigation {
    .ant-menu-title-content {
      display: none !important;
    }
  }
}

.ant-alert {
  background-color: map_get($theme-colors, primary_color);
}

.ant-layout-sider-trigger {
  background-color: map_get($theme-colors, primary_color) !important;
  color: map_get($theme-colors, dark_text_color) !important;
  border-right: 1px solid map_get($theme-colors, color_border);
}

.ant-menu {
  background-color: map_get($theme-colors, primary_color);
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 1px solid map_get($theme-colors, color_border);
}

.ant-divider {
  height: 2px;
  background-color: map_get($theme-colors, color_border);
}

.ant-modal .ant-modal-content {
  border: 1px solid map_get($theme-colors, color_border);
  background-color: map_get($theme-colors, primary_color);
  color: map_get($theme-colors, text_color);
}

.ant-modal .ant-modal-header {
  background-color: map_get($theme-colors, primary_color);
  .ant-modal-title {
    color: map_get($theme-colors, text_color);
  }
}

.ant-modal-close-icon {
  color: map_get($theme-colors, text_color);
}

.ant-input {
  background-color: map_get($theme-colors, background_color);
  color: map_get($theme-colors, text_color);
  &:disabled {
    background-color: map_get($theme-colors, dark_secondary_color);
    color: map_get($theme-colors, dark_text_color);
  }
  &::placeholder {
    color: map_get($theme-colors, dark_text_color) !important;
  }
}

.ant-card {
  box-shadow: rgba(149, 157, 165, 0.116) 0px 8px 24px;
}

.ant-card,
.ant-card-bordered {
  background-color: map_get($theme-colors, primary_color) !important;
  border: 1px solid map_get($theme-colors, color_border) !important;
  .card-header-inner {
    padding: 0px 25px;
    display: flex;
    justify-content: space-between;
  }
}

.ant-card-head {
  padding: 0px 0px !important;
  background-color: map_get($theme-colors, primary_color) !important;
}

.login-container {
  .ant-btn {
    border: 1px solid rgb(148, 140, 140) !important;
  }
}

.ant-btn {
  background-color: map_get($theme-colors, highlight_color) !important;
  border-radius: 100px;
  color: map_get($theme-colors, text_color) !important;
  &.ant-btn-dashed {
    background-color: map_get($theme-colors, primary_color) !important;
  }
  &.ant-btn-primary {
    font-weight: bold;
    background-color: map_get($theme-colors, highlight_color) !important;
    color: map_get($theme-colors, text_color_contrast) !important;
  }

  &.ant-btn-default {
    background-color: map_get($theme-colors, secondary_color) !important;
  }

  &.btn-success {
    background-color: map_get($theme-colors, success_color) !important;
  }

  &.btn-error {
    background-color: map_get($theme-colors, error_color) !important;
  }

  &.ant-btn-primary,
  &.ant-btn-default {
    box-shadow: none;
    &:hover {
      background-color: map_get($theme-colors, highlight_color) !important;
      color: map_get($theme-colors, text_color_contrast) !important;
      font-weight: bold;
    }
    &:disabled {
      background-color: map_get($theme-colors, secondary_color) !important;
      color: map_get($theme-colors, dark_text_color) !important;
      &:hover {
        font-weight: normal;
      }
    }
  }
}

.ant-menu-item {
  border-radius: 10 !important;
  &.ant-menu-item-active,
  &.ant-menu-item-selected {
    background-color: map_get($theme-colors, highlight_color);
    color: map_get($theme-colors, dark_text_color);
  }
}

.ant-select {
  color: map_get($theme-colors, text_color) !important;
  background-color: map_get($theme-colors, background_color) !important;
  &::placeholder {
    color: map_get($theme-colors, secondary_text_color) !important;
  }
}

.select-popup-style {
  background-color: white !important;
  color: map_get($theme-colors, text_color) !important;
}

.select-popup-style .ant-select-dropdown-menu-item-group-title {
  color: map_get($theme-colors, text_color) !important;
  font-weight: bold;
}

.select-popup-style .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.select-popup-style .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.select-popup-style .ant-select-dropdown-menu {
  max-height: 300px;
}

.ant-select-selector {
  background-color: map_get($theme-colors, background_color) !important;
  color: map_get($theme-colors, text_color);
}
.ant-select-selection-item {
  background-color: map_get($theme-colors, background_color);
  color: map_get($theme-colors, text_color);
  border: none;
}

.window-tab-item {
  padding: 5px 2px 5px 8px;
  border-radius: 10px;
  margin-bottom: 4px;
  &:hover {
    background-color: map_get($theme-colors, dark_secondary_color);
    .directory-name {
      text-decoration: 2.2px underline;
    }
  }
  &.window-tab-item-selected {
    background-color: map_get($theme-colors, light_highlight_color);
  }
}

.App-link {
  color: map_get($theme-colors, highlight_color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid map_get($theme-colors, color_border);
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

.ant-select-dropdown {
  color: map-get($theme-colors, text_color) !important;
  border: 0.5px solid map-get($theme-colors, secondary_color) !important;
}
.ant-select-item-option-selected {
  background-color: map-get($theme-colors, secondary_color) !important;
}

.ant-modal-confirm-title {
  color: map_get($theme-colors, text_color) !important;
}

.ant-modal-confirm-content {
  color: map_get($theme-colors, text_color) !important;
}

.ant-btn.ant-btn-default {
  background-color: map_get($theme-colors, primary_color) !important;
  &.ant-btn-sider {
    background-color: map_get($theme-colors, primary_color) !important;
    border: none !important;
    padding: 10px 0px 20px 20px;
    height: 40px;
    color: map-get($theme-colors, text_color) !important;
    &:hover {
      color: map-get($theme-colors, highlight_color) !important;
    }
  }
}

.ant-skeleton {
  color: map_get($theme-colors, dark_secondary_color);
}

.ant-breadcrumb .ant-breadcrumb-link {
  color: map_get($theme-colors, dark_highlight_color) !important;
  font-size: 16px;
}
.ant-breadcrumb .ant-breadcrumb-separator {
  color: map_get($theme-colors, dark_highlight_color) !important;
  font-size: 16px;
}

.ant-collapse {
  background-color: white;
  border: 1px solid map_get($theme-colors, color_border) !important;
  box-shadow: rgba(149, 157, 165, 0.065) 0px 8px 24px;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}

.ant-collapse-content {
  background-color: white;
}

.ant-collapse-content-box {
  background-color: white;
}

.no-padding {
  padding: 0 !important;
}

$accent-color: rgb(73, 73, 73);
$border-width: 0.7px;

.powerful-search-container {
  overflow: hidden;
  position: relative;
  background-color: map_get($theme-colors, primary_color);
  min-height: 40px;
  height: fit-content;
  border-radius: 5px;
  padding: 10px 0px;
  border: $border-width solid map_get($theme-colors, color_border);
}

.powerful-search-container {
  width: 500px;
}

.powerful-search-scrollable-area {
  height: fit-content;
  overflow: auto;
  max-height: 400px;
}

.powerful-search-inner {
  padding: 0px 20px;
  width: 100%;
  height: 40px;
  display: flex;
  text-align: left;
  align-items: center;
}

input:focus {
  outline: none;
}

.powerful-search-button {
  width: fit-content;
  .powerful-search-input {
    cursor: pointer;
    color: map_get($theme-colors, dark_text_color) !important;
    background-color: map_get($theme-colors, background_color) !important;
    border: 1px solid map_get($theme-colors, color_border) !important;
    border-radius: 20px;
    color: map_get($theme-colors, text_color);
    font-size: 12px;
    margin-left: 10px;
    width: 100%;
    padding: 8px 10px;
    background-color: transparent;
    &:focus {
      border: 1px solid map_get($theme-colors, color_border) !important;
    }
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
  }
}

.powerful-search-input {
  color: map_get($theme-colors, text_color);
  font-size: larger;
  margin-left: 10px;
  width: 100%;
  background-color: transparent;
  border: none;
  &:focus {
    border: none !important;
  }
}

.beautiful-divider {
  width: 100%;
  height: $border-width;
  background-color: map_get($theme-colors, dark_secondary_color);
  margin: 10px 0px;
}

.powerful-action-item {
  color: map_get($theme-colors, text_color);
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  &:hover {
    background-color: map_get($theme-colors, dark_secondary_color);
    cursor: pointer;
  }
}

.powerful-shortcut-tag {
  font-weight: bold;
  font-size: smaller;
  .powerful-shortcut-inner {
    border: 1px solid map_get($theme-colors, dark_secondary_color);
    padding: 3px 5px;
    border-radius: 5px;
    background-color: $accent-color;
  }
}

.beautiful-section-header {
  font-size: 20;
}

.beautiful-section-header-container {
  padding: 0px 20px;
  justify-content: space-between !important;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(4%);
  }
  65% {
    transform: translateY(-2%);
  }
  80% {
    transform: translateY(2%);
  }
  95% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0%);
  }
}

.context-menu-popup {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  z-index: 100;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.image-container {
  position: relative;
  display: inline-block; /* or block, depending on your layout */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
}

.text-container {
  position: relative;
  z-index: 1; /* Ensure the text appears above the overlay */
  /* Additional styling for the text container */
}

.tab-href {
  color: map-get($map: $theme-colors, $key: dark_text_color);
  // &:hover {
  //   color: map-get($map: $theme-colors, $key: highlight_color);
  // }
}

.even-treenode {
  background-color: map-get($map: $theme-colors, $key: secondary_color);
  border-radius: 10px;
}

.selection-area {
  background: #4f90f22d;
  border: 1px solid #4f90f2;
}
