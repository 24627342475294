.ant-modal.directory-search-modal .ant-modal-content {
  border: none !important;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  box-shadow: none;
  align-items: center;
  width: 100%;
}

.ant-modal .ant-modal-header {
  background-color: transparent !important;
}
